import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This guide will show you how to upgrade from the now deprecated
`}<inlineCode parentName="p">{`Primer::ButtonComponent`}</inlineCode>{` to the latest `}<a parentName="p" {...{
        "href": "/components/button/rails"
      }}><inlineCode parentName="a">{`Primer::Beta::Button`}</inlineCode></a>{`
component.`}</p>
    <h2>{`Arguments`}</h2>
    <p>{`The following arguments for the component initializer have changed between the deprecated and newer versions
of the Primer Button.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`From `}<inlineCode parentName="th">{`Primer::ButtonComponent`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`To `}<inlineCode parentName="th">{`Primer::Beta::Button`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dropdown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`removed in `}<inlineCode parentName="td">{`Primer::Beta::Button`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`group_item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`removed in `}<inlineCode parentName="td">{`Primer::Beta::Button`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scheme`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scheme`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`removed `}<inlineCode parentName="td">{`:outline`}</inlineCode>{` value. use `}<inlineCode parentName="td">{`:invisible`}</inlineCode>{` instead`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`variant`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`renamed; values remain the same`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The remaining arguments have stayed the same.`}</p>
    <p><inlineCode parentName="p">{`Primer::Beta::Button`}</inlineCode>{` no longer supports the boolean settings for being part of
a group, nor the dropdown caret via initializer argument. See the following for examples of how to replace
this functionality.`}</p>
    <ul>
      <li parentName="ul">{`Button groups: `}<a parentName="li" {...{
          "href": "/components/beta/buttongroup"
        }}><inlineCode parentName="a">{`Primer::Beta::ButtonGroup`}</inlineCode></a></li>
      <li parentName="ul">{`Trailing counter: `}<a parentName="li" {...{
          "href": "https://primer.style/view-components/lookbook/inspect/primer/beta/button/trailing_visual"
        }}><inlineCode parentName="a">{`trailing_visual`}</inlineCode>{` slot`}</a></li>
      <li parentName="ul">{`Trailing caret: `}<a parentName="li" {...{
          "href": "https://primer.style/view-components/lookbook/inspect/primer/beta/button/trailing_action"
        }}><inlineCode parentName="a">{`trailing_action`}</inlineCode>{` slot`}</a></li>
    </ul>
    <h2>{`Slot Names`}</h2>
    <p>{`The following slots have changed with the newer Primer Button.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`From `}<inlineCode parentName="th">{`Primer::ButtonComponent`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`To `}<inlineCode parentName="th">{`Primer::Beta::Button`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`trailing_action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`appears to the right of the `}<inlineCode parentName="td">{`trailing_visual`}</inlineCode>{` slot`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The remaining slot names have stayed the same.`}</p>
    <p><a parentName="p" {...{
        "href": "/guides/development/rails#upgrading-deprecated-components"
      }}>{`←`}{` Back to development docs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      